import "./Construction.css";
import AboutBody from "../../components/About/About-body";
import Navbar from "../../components/Navbar/Navbar";
import Laurie from "../../assets/Laurie.svg";
import { useState, useEffect, useRef } from "react";

export default function Construction() {
  const heroRef = useRef(null);

  useEffect(() => {
    const updateMousePosition = (ev) => {
      if (!heroRef.current) return;
      const { clientX, clientY } = ev;
      heroRef.current.style.setProperty("--x", `${clientX}px`);
      heroRef.current.style.setProperty("--y", `${clientY}px`);
    };
    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <div ref={heroRef} className="construction-bg construction-font">
      UNDER CONSTRUCTION
    </div>
  );
}
