import "./Resume.css";
import { useEffect, useState } from "react";

export default function TestLaurieResume() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <div>hiii</div>
  ) : (
    <div className="testresume">
      <div>
        <p className="resume-header">Laurie Himmel</p>
      </div>

      <div>
        <p className="resume-section-header">SUMMARY</p>
        <p className="resume-text">
          Find me at the intersection of creativity and tech. As a passionate
          developer with a foundation in creative pursuits, I craft innovative
          digital experiences and problem-solve in unique ways.{" "}
        </p>
      </div>

      <div>
        <p className="resume-section-header">TECHNICAL SKILLS</p>
        <p className="resume-text">
          HTML | JavaScript | CSS | Git | Github | Node | Express.js | RESTful
          API Routing | JSON | React | MongoDB | SQL | Django | Adobe Creative
          Cloud | Airtable | Asana | Google Analytics | Hubspot | Shopify{" "}
        </p>
      </div>

      <div>
        <p className="resume-section-header">SOFT SKILLS </p>
        <p className="resume-text">
          Project Management | Relationship Building | Team Player |
          Adaptability | Organization | Communication | Quick Learning |
          Problem-Solving | Cross-Functional Collaboration | Time Management
        </p>
      </div>

      <div>
        <p className="resume-section-header">WORK EXPERIENCE </p>
      </div>
      <div>
        <p>PROJECT MANAGER</p>
        <p>Social House, Inc.</p>
        <p className="resume-text">
          Accountable for the successful delivery of projects on time and within
          budget Provided strategic direction to project teams as well as
          tactically handled the day-to-day project operations Developed and
          finalized company's workflow processes for projects across all
          departments Translated workflows for company into Asana and trained
          all teams on Asana use Managed multiple full-service projects
          simultaneously across multiple clients Coordinated cross-functional
          communication and handoffs across disciplines Assigned, monitored and
          guided workflow and tasks; including setting daily, weekly, and
          long-term project goals and report status
        </p>
      </div>

      <div>
        <p>DIGITAL CONTENT MANAGER</p>
        <p>MARKETING PRODUCER + COORDINATOR</p>
        <p>Sock It to Me </p>
        <p className="resume-text">
          Established efficient product management processes, optimizing content
          creation and product development Collaborated with web development
          team to design and build brand’s new website Coordinated customer
          feedback with the web development team, prioritizing consumer
          experience and implementing necessary improvements Led a team of 4
          creatives in conceptualizing and producing deliverables for various
          campaign briefs, including photography, website graphics, social media
          content, and campaign promotions Collaborated with cross-functional
          teams to develop the Product Lifecycle Management tool, driving
          successful implementation across 15+ product launches Maintained
          seamless communication and collaboration with sales, operations, and
          design departments, as well as external stakeholders, ensuring
          alignment with marketing objectives Engaged directly with the CEO and
          founder to maintain brand voice and messaging consistency, reflecting
          the core values and identity of Sock It to Me Delegated tasks to
          developers, graphic designers, and marketing coordinators, fostering a
          collaborative and productive work environment Effectively managed
          cross-departmental calendars to synchronize marketing campaigns and
          translated marketing schedules into engaging and impactful campaigns
          Pioneered and led a task force focused on establishing initiatives,
          internal systems, and accountability measures to foster inclusivity
          and diversity within the organization
        </p>
      </div>

      <div>
        <p className="resume-section-header">EDUCATION </p>
        <p>
          General Assembly Software Engineering Immersive Full-stack software
          engineering immersive student in an intensive, 12-week, 480+ hour
          program focused on product development fundamentals, object-oriented
          programming, MVC frameworks, data modeling, and team collaboration
          strategies. Developed a portfolio of individual and group projects.
        </p>

        <p>
          Boulder Digital Arts Graphic Design Certificate Program, After Effects
          Certificate Program{" "}
        </p>
        <p>New York University New York, NY BFA, Drama</p>
      </div>
    </div>
  );
}
