import LaurieResume from "../../components/Resume/Resume";
import ResumeCopy from "../../components/Resume/Resume-copy";

export default function ResumePage () {
  return (
    <>
    <ResumeCopy />
    <LaurieResume />
    </>
  )
}